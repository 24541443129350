<template>
  <div>
    <card-element>
      <div class='flex items-start'>
        <img class='lab py-8'
          src='images/a59d.jpg'
          v-if='card.brand === "visa"'>
        <img class='lab py-8'
          src='images/3be5.jpg'
          v-if='card.brand === "mastercard"'>
        <img class='lab py-8'
          src='images/9a31.jpg'
          v-if='card.brand === "jcb"'>
      </div>
      <div class='flex mt-24'>
        <input-element class='xob w-full'
          disabled
          :label='$t("common.card-number")'
          :value='maskString(card.number, "???? ???? ???? ####")'/>
        <input-element class='xob ml-24'
          disabled
          :label='$t("common.expiry-date")'
          :value='maskString(card.expirationMonth.toString().padStart(2, "0") + card.expirationYear.toString().slice(2), "##/##")'/>
      </div>
    </card-element>
  </div>
</template>

<script>
  import maskMixin from '@/mixins/mask'

  export default {
    mixins: [maskMixin],

    props: {
      card: Object
    }
  }
</script>

<style lang='scss' scoped>
  .xob::v-deep .inp {
    @apply px-0;
  }

  .lab {
    height: 40px;
  }
</style>
