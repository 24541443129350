const getStripe = function () {
  if (window.getStripe) return window.getStripe

  window.getStripe = new Promise(resolve => {
    let script = document.createElement('script')

    script.src = 'https://js.stripe.com/v3'
    script.addEventListener('load', () => {
      let locale = localStorage.getItem('locale') || 'en'
      let stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY, { locale })
      resolve(stripe)
    })

    document.head.appendChild(script)
  })

  return window.getStripe
}

const createElements = async function (clientSecret) {
  let stripe = await getStripe()

  let appearance = {
    theme: 'stripe',
    variables: {
      colorText: '#1a202c',
      colorTextSecondary: '#4a5568',
      colorPrimary: '#3743bd',
      colorDanger: '#f56565',
      spacingGridColumn: '16px',
      spacingGridRow: '24px',
      fontFamily: '\'Volte\', sans-serif',
      borderRadius: '0.25rem'
    }
  }

  let fonts = [
    {
      family: 'Volte',
      weight: '400',
      src: `url(${process.env.VUE_APP_ORIGIN}/fonts/volte.5099295d.woff)`
    },
    {
      family: 'Volte',
      weight: '500',
      src: `url(${process.env.VUE_APP_ORIGIN}/fonts/volte-medium.17c4b8a9.woff)`
    },
    {
      family: 'Volte',
      weight: '400',
      src: `url(${process.env.VUE_APP_ORIGIN}/fonts/noto-sans-thai-light.f2615b23.woff)`,
      unicodeRange: 'U+0E01-0E5B, U+200C-200D, U+25CC'
    },
    {
      family: 'Volte',
      weight: '500',
      src: `url(${process.env.VUE_APP_ORIGIN}/fonts/noto-sans-thai.0374a0a7.woff)`,
      unicodeRange: 'U+0E01-0E5B, U+200C-200D, U+25CC'
    }
  ]

  return stripe.elements({ clientSecret, appearance, fonts })
}

const confirmSetup = async function (elements) {
  let stripe = await getStripe()

  let { error } = await stripe.confirmSetup({
    elements,
    confirmParams: {
      return_url: location.href
    }
  })

  if (error) throw error
}

const confirmPayment = async function (clientSecret, paymentId) {
  let stripe = await getStripe()

  let { error } = await stripe.confirmCardPayment(clientSecret, {
    payment_method: paymentId
  })

  if (error) throw error
}

const retrieveSetupIntentResult = async function () {
  let params = new URLSearchParams(location.search)
  let clientSecret = params.get('setup_intent_client_secret')

  if (!clientSecret) return null

  let stripe = await getStripe()
  let { setupIntent, error } = await stripe.retrieveSetupIntent(clientSecret)

  if (error) throw error

  history.replaceState(null, '', location.pathname)

  return { status: setupIntent.status }
}

export default {
  createElements,
  confirmSetup,
  confirmPayment,
  retrieveSetupIntentResult
}
