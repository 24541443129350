export default {
  methods: {
    maskString (value, mask) {
      let valueChars = value.split('')
      let maskChars = mask.split('')
      let result = ''

      while (valueChars.length && maskChars.length) {
        switch (maskChars[0]) {
          case '#':
            /\d/.test(valueChars[0])
              ? result += valueChars.shift()
              : valueChars = []
            maskChars.shift()
            break
          case '?':
            result += valueChars.shift()
            maskChars.shift()
            break
          default:
            if (valueChars[0] === maskChars[0]) valueChars.shift()
            result += maskChars.shift()
        }
      }

      return result
    },

    unmaskString (value, mask) {
      let valueChars = value.split('')
      let maskChars = mask.split('')
      let result = ''

      while (valueChars.length && maskChars.length) {
        switch (maskChars.shift()) {
          case '#':
          case '?':
            result += valueChars.shift()
            break
          default:
            valueChars.shift()
        }
      }

      return result
    }
  }
}
